import * as React from "react";
import styled from "styled-components";
import Layout from "../components/layout/layout";
import Main1 from "../components/main/main1";
import Main2 from "../components/main/main2";
import Main3 from "../components/main/main3";
import Main5 from "../components/main/main5";
import Main6 from "../components/main/main6";
import Main7 from "../components/main/main7";
import Main8 from "../components/main/main8";
import Main9 from "../components/main/main9";
import {Helmet} from "react-helmet";
//import Main4 from "../components/main/main4"; 샘오더
const Containar = styled.div``;
const tag_title = "샘랩";
const tag_description = "O2O 교육 학습 플랫폼을 만드는 에듀테크 기업 샘랩입니다";
// markup
const IndexPage = () => {
  const [scrollPosition, setScrollPosition] = React.useState(0);
  const updateScroll = () => {
    let scrollTop = window.scrollY;
    let docHeight = document.body.offsetHeight;
    let winHeight = window.innerHeight;
    let scrollPercent = scrollTop / (docHeight - winHeight);
    let scrollPercentRounded = Math.round(scrollPercent * 100);
    setScrollPosition(scrollPercentRounded);
  };
  const [isDeviceVer, setIsDeviceVer] = React.useState(0);

  let deviceH;
  let deviceW;

  if (typeof window !== `undefined`) {
    deviceH = window.innerHeight;
    deviceW = window.innerWidth;
  }

  React.useEffect(() => {
    window.addEventListener("scroll", updateScroll);
    if (deviceH && deviceW && deviceW > 460 && deviceW < 1020 && deviceH > deviceW) {
      setIsDeviceVer(1);
    } else if (
      (deviceH && deviceW && deviceW > 1020 && deviceH > deviceW) ||
      deviceW - deviceH < 150
    ) {
      setIsDeviceVer(2);
    }
  }, []);

  return (
    <Layout>
            <Helmet>
        <title>{tag_title}</title>
        <meta name="title" content={tag_title} />
        <meta name="description" content={tag_description} />
        <meta property="og:title" content={tag_title} />
        <meta property="og:description" content={tag_description} />
        <meta property="og:url" content="https://samlab.co.kr" />
        <meta property="twitter:title" content={tag_title} />
        <meta property="twitter:description" content={tag_description} />
        <meta property="twitter:url" content="https://samlab.co.kr" />
      </Helmet>
      <Main1 />
      <Main8 scrollPosition={scrollPosition} isDeviceVer={isDeviceVer} />
      <Main9 scrollPosition={scrollPosition} isDeviceVer={isDeviceVer} />
      <Main3 />
      <Main7 scrollPosition={scrollPosition} />
      <Main2 scrollPosition={scrollPosition} />
      <Main5 scrollPosition={scrollPosition} isDeviceVer={isDeviceVer} />
      <Main6 scrollPosition={scrollPosition} />
    </Layout>
  );
};

export default IndexPage;
