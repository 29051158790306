import * as React from "react";
import arrow from "../../images/arrow.svg";
import arrowActive from "../../images/arrow-active.svg";
import message1 from "../../images/main2/message1.png";
import message2 from "../../images/main2/message2.png";
import message3 from "../../images/main2/message3.png";
import message4 from "../../images/main2/message4.png";
import message5 from "../../images/main2/message5.png";
import person1 from "../../images/main2/person1.png";
import person2 from "../../images/main2/person2.png";
import person3 from "../../images/main2/person3.png";
import person4 from "../../images/main2/person4.png";
import person5 from "../../images/main2/person5.png";
import person6 from "../../images/main2/person6.png";
import person7 from "../../images/main2/person7.png";
import Img_480 from "../../images/main2/show-480.png";
import { GhostBtn } from "./main5";
import { Link } from "gatsby";
import "./animate.css";
import useScrollFadeIn from "../useScrollFadein";
import styled from "styled-components";
const Containar = styled.div`
  display: flex;
  justify-content: space-between;
  height: 55vw;
  align-items: center;
  position: relative;
  .main2-left-div {
    width: 26%;
    align-items: flex-start;
    display: flex;
    flex-flow: column;
    justify-content: center;
    padding-left: 19vw;
    .main2-title {
      font-size: 3.1vw;
      font-family: "Pretendard-ExtraBold";
      line-height: 1.2;
      margin-bottom: 3vw;
      display: flex;
      flex-direction: column;
      span:first-child {
        color: #999999;
      }
    }
    .ghostButton {
      padding: 1vw;
      font-size: 1.5vw;
      background-color: transparent;
      border-radius: 0.5vw;
      border: 1.5px solid #000000;
      margin-top: 3vw;
    }
  }
  .main2-right-div {
    width: 71%;
    margin-top: -45vw;
    .main2-img-group {
      position: absolute;
    }

    .main2-image1 {
      width: 18vw;
      margin-top: 3vw;
    }

    .main2-image2 {
      width: 16.5vw;
      margin-left: 40vw;
      margin-top: 5vw;
    }

    .main2-image3 {
      width: 17.5vw;
      margin-left: 40vw;
      margin-top: 10.5vw;
    }

    .main2-image4 {
      width: 13vw;
      margin-left: 8vw;
      margin-top: 28.5vw;
    }

    .main2-image5 {
      width: 23vw;
      z-index: 2;
      margin: 36vw 0px 0 29vw;
    }

    .main2-image6 {
      z-index: 2;
      width: 15vw;
      margin-top: 8vw;
      margin-left: 7vw;
    }

    .main2-image7 {
      width: 19vw;
      margin-left: 23vw;
      margin-top: 4.5vw;
    }
    .main2-image8 {
      width: 17vw;
      margin: 22vw 0 0 37.5vw;
    }

    .main2-image9 {
      width: 13vw;
      margin: 14.5vw 0 0 46vw;
      z-index: -2;
    }
    .main2-image10 {
      width: 10vw;
      margin-top: 3vw;
      margin-left: 15vw;
      z-index: -3;
    }

    .main2-image11 {
      width: 10vw;
      margin-left: 37vw;
      z-index: -3;
    }
    .main2-image12 {
      width: 10vw;
      z-index: -3;
      margin-left: 17vw;
      margin-top: 20vw;
    }
  }

  .show-480 {
    display: none;
  }
  @media screen and (max-width: 1020px) {
    height: 65vw;
    .main2-left-div {
      width: 44vw;
      padding-left: 10%;
      .main2-title {
        font-size: 4.6vw;
        display: flex;
        flex-direction: column;
      }
    }
    .main2-right-div {
      .main2-image1 {
        top: 2vw;
        left: 43vw;
        width: 31vw;
      }
    }
  }
  @media screen and (max-width: 460px) {
    height: auto;
    flex-direction: column;
    .main2-left-div {
      width: 100%;
      padding-left: 0;
      margin: 18% 0;
      padding-left: 7.222vw;
      .main2-title {
        font-size: 7.5vw;
        display: flex;
        flex-direction: column;
      }

      a {
        width: 50.278vw;
        height: 13.333vw;
      }
    }
    .main2-right-div {
      margin-top: 0;
      width: 100%;
    }

    .hide-480 {
      display: none;
    }

    .show-480 {
      display: block;
      .main2-image13 {
        width: 90vw;
        margin: 0 auto 15vw;
      }
    }
  }
`;

// markup
const Main2 = ({ scrollPosition }) => {
  const animation = {
    0: useScrollFadeIn("up", 1, 0),
    1: useScrollFadeIn("up", 1, 0.1),
    2: useScrollFadeIn("up", 1, 0.2),
  };

  return (
    <Containar>
      <div className="main2-left-div">
        <div className="main2-title">
          <span {...animation[0]}>함께 하면</span>
          <span {...animation[1]}> 더 나은 하루를</span>
          <span {...animation[2]}>보낼 수 있습니다.</span>
        </div>

        <Link to="/letmeup">
          <GhostBtn>
            <p>Platform Business</p>
            <div className="icon-mask">
              <object className="icon" data={arrow} />
              <object className="icon" data={arrowActive} />
            </div>
          </GhostBtn>
        </Link>
      </div>
      <div className="main2-right-div hide-480">
        <img
        alt={'img33'}
          className={
            scrollPosition > 47
              ? "main2-img-group main2-image1 animate__animated animate__fadeInLeft"
              : "main2-img-group main2-image1"
          }
          src={message1}
        />
        <img
        alt={'img34'}

          className={
            scrollPosition > 47
              ? "main2-img-group main2-image2 animate__animated animate__fadeInRight animate__delay-1s"
              : "main2-img-group main2-image2"
          }
          src={message2}
        />
        <img
        alt={'img35'}

          className={
            scrollPosition > 48.5
              ? "main2-img-group main2-image3 animate__animated animate__fadeInRight animate__delay-1s"
              : "main2-img-group main2-image3"
          }
          src={message3}
        />
        <img
        alt={'img36'}

          className={
            scrollPosition > 50
              ? "main2-img-group main2-image4 animate__animated animate__fadeInDown  animate__delay-1s"
              : "main2-img-group main2-image4"
          }
          src={message4}
        />
        <img
        alt={'img37'}

          className={
            scrollPosition > 50
              ? "main2-img-group main2-image5 animate__animated animate__fadeInDown"
              : "main2-img-group main2-image5"
          }
          src={message5}
        />
        <img
        alt={'img38'}

          className={
            scrollPosition > 51
              ? "main2-img-group main2-image6 animate__animated animate__fadeInLeft"
              : "main2-img-group main2-image6"
          }
          src={person1}
        />
        <img
        alt={'img39'}

          className={
            scrollPosition > 51
              ? "main2-img-group main2-image7 animate__animated animate__fadeInUp"
              : "main2-img-group main2-image7"
          }
          src={person2}
        />
        <img
          className={
            scrollPosition > 52
              ? "main2-img-group main2-image8 animate__animated animate__fadeInRight animated__slower"
              : "main2-img-group main2-image8"
          }
          src={person3}
        />
        <img
        alt={'img40'}

          className={
            scrollPosition > 52.3
              ? "main2-img-group main2-image9 animate__animated animate__fadeInRight animated__faster animate__delay-1s"
              : "main2-img-group main2-image9"
          }
          src={person4}
        />
        <img alt={'img41'} className="main2-img-group main2-image10" src={person5} />
        <img alt={'img42'} className="main2-img-group main2-image11" src={person6} />
        <img alt={'img43'} className="main2-img-group main2-image12" src={person7} />
      </div>
      <div className="show-480">
        <img alt={'img44'} className="main2-image13" src={Img_480} />
      </div>
    </Containar>
  );
};

export default Main2;
