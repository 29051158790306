import * as React from "react";
import arrow from "../../images/arrow.svg";
import arrowActive from "../../images/arrow-active.svg";
import outSideKiosk from "../../images/main9/외부_렛미업.svg";
import m_outSideKiosk from "../../images/main9/m_외부_렛미업_x3.png";
import inSideKiosk from "../../images/main9/내부_렛미업.svg";
import m_inSideKiosk from "../../images/main9/m_내부_렛미업_x3.png";

import logo from "../../images/main9/s_렛미업로고.png";
import icon1 from "../../images/main9/e_지문아이콘.png";
import icon2 from "../../images/main9/e_퇴실아이콘.png";
import message1 from "../../images/main9/e_지문인증된다고_.png";
import m_message1 from "../../images/main9/e_지문인증.svg";

import { mainNotionUrl } from "../../data/url";
import { GhostBtn } from "./main5";
import { Link } from "gatsby";
import "./animate.css";
import useScrollFadeIn from "../useScrollFadein";
import styled from "styled-components";
import { Timeline, Tween } from "react-gsap";
import { Controller, Scene } from "react-scrollmagic";

const Container = styled.div`
  .hide-480 {
    display: flex;
    overflow: hidden;
    position: relative;
    top: 0;
    left: 0;
    .main9-left-div {
      position: absolute;
      width: 30.417vw;
      height: 23.75vw;
      left: 66.406vw;
      top: 16.51vw;
      .logo {
        width: 9.583vw;
        /* height: 1.667vw; */
        margin-bottom: 1.094vw;
      }
      .main9-title {
        font-size: 3.1vw;
        font-family: "Pretendard-ExtraBold";
        line-height: 1.2;
        margin-bottom: 3vw;
        display: flex;
        flex-direction: column;
      }
      .main9-text {
        font-family: "Pretendard-Medium";
        font-style: normal;
        font-weight: 500;
        font-size: 1.25vw;
        line-height: 1.2;
        color: #555555;
        margin-top: 1.25vw;
        margin-bottom: 2.448vw;
      }
      .ghostButton {
        padding: 1vw;
        font-size: 1.5vw;
        background-color: transparent;
        border-radius: 0.5vw;
        border: 1.5px solid #000000;
        margin-top: 3vw;
      }
    }
    //외부 키오스크
    .img1 {
      position: absolute;
      width: 37.24vw;
      height: 52.604vw;
      left: 3.542vw;
      top: 2.865vw;
      opacity: 1;
    }
    //내부 키오스크
    .img2 {
      position: absolute;
      width: 32.656vw;
      height: 39.635vw;
      left: 7.917vw;
      top: 9.792vw;
      opacity: 1;
    }
    //아이콘 1
    .img3 {
      position: absolute;
      width: 9.3vw;
      height: 9.3vw;
      left: 42.083vw;
      top: 17.225vw;
    }
    // 아이콘2
    .img4 {
      position: absolute;
      width: 6.191vw;
      height: 6.191vw;
      left: 51.615vw;
      top: 10.503vw;
      z-index: 1;
    }
    // 메세지1
    .img5 {
      position: absolute;
      width: 16.268vw;
      height: 6.688vw;
      left: 33.542vw;
      top: 5.469vw;
    }
    .sticky,
    .sticky2 {
      height: 60vw;

      & .animation,
      .animation2 {
      }
    }
    .hidden {
      display: none;
    }
    .m-hide {
      display: none;
    }
  }

  .show-480 {
    display: none;
  }
  @media screen and (max-width: 1020px) and (orientation: portrait) {
    .hide-480 {
      /* margin: 10vw 0; */
      padding-bottom: 30vw;
      margin-bottom: 30vw;
      .main9-left-div {
        width: 50vw;
        left: 10vw;
        top: 6vw;
        .logo {
          width: 17vw;
        }
        .main9-title {
          font-size: 4.6vw;
          display: flex;
        flex-direction: column;
        }
        .main9-text {
          font-size: 2vw;
        }
      }
      //외부 키오스크
      .img1 {
        width: 33rem;
        height: fit-content;
        left: 15rem;
        top: 26.5rem;
      }
      //내부 키오스크
      .img2 {
        width: 35rem;
        height: fit-content;
        left: 12rem;
        top: 35rem;
      }
      //아이콘 2
      .img3 {
        width: 12.3vw;
        height: fit-content;
        left: 63.083vw;
        top: 17.225vw;
      }
      // 아이콘2
      .img4 {
        width: 6.191vw;
        height: fit-content;
        left: 51.615vw;
        top: 10.503vw;
      }
      // 메세지1
      .img5 {
        width: 26.438vw;
        height: fit-content;
        left: 62vw;
        top: 35.521vw;
      }
      .sticky,
      .sticky2 {
        height: 120vw;
      }
    }
  }
  @media screen and (max-width: 690px) and (orientation: portrait) {
    .hide-480 {
      //외부 키오스크
      .img1 {
        width: 22rem;
        height: fit-content;
        left: 16rem;
        top: 17.5rem;
      }
      //내부 키오스크
      .img2 {
        width: 23rem;
        height: fit-content;
        left: 14rem;
        top: 23.5rem;
      }
      //아이콘 2
      .img3 {
        width: 12.3vw;
        height: fit-content;
        left: 63.083vw;
        top: 17.225vw;
      }
      // 아이콘2
      .img4 {
        width: 6.191vw;
        height: fit-content;
        left: 51.615vw;
        top: 10.503vw;
      }
      // 메세지1
      .img5 {
        width: 23.438vw;
        height: fit-content;
        left: 64vw;
        top: 35.521vw;
      }
      .sticky,
      .sticky2 {
        height: 90vw;
      }
    }
  }
  @media screen and (max-width: 460px) {
    height: 202.222vw;
    .main9-right-div {
      margin-top: 0;
      width: 100%;
    }

    .hide-480 {
      display: none;
    }

    .show-480 {
      display: flex;
      flex-direction: column;
      position: relative;
      width: 100%;
      height: 100%;
      .icon1 {
        position: absolute;
        width: 32.778vw;
        height: 32.778vw;
        left: 67.222vw;
        top: 18.889vw;
      }

      .icon2 {
        position: absolute;
        width: 25.769vw;
        height: 25.769vw;
        left: 45.556vw;
        top: 10.278vw;
      }
      .main9-top-div {
        display: flex;
        flex-direction: column;
        justify-content: left;
        margin-top: 34.444vw;
        margin-left: 6.667vw;
        .logo {
          width: 106px;
          height: 18px;
          left: 24px;
          top: 34.444vw;
          margin-bottom: 2.778vw;
        }

        .main9-title {
          font-family: "Pretendard-ExtraBold";
          font-size: 8.333vw;
          line-height: 10vw;
          display: flex;
        flex-direction: column;
        }

        .main9-text {
          font-family: "Pretendard-Medium";
          font-style: normal;
          font-weight: 500;
          font-size: 4.444vw;
          line-height: 5.278vw;
          margin-top: 4.444vw;
          margin-bottom: 12.778vw;
          color: #555555;
        }
        a {
          width: 40.556vw;
          height: 13.333vw;
        }
      }

      .main9-img-div {
        position: relative;
        height: 100%;
        .img1 {
          position: absolute;
          /* svg
          width: 53.275vw;
          height: 75.003vw;
          left: 18.222vw;
          top: 8.056vw; */

          //png
          width: 45vw;
          height: 74.55vw;
          left: 18.222vw;
          top: 8.056vw;
        }
        .img2 {
          position: absolute;
          width: 36.539vw;
          height: 46.258vw;
          left: 46.961vw;
          top: 43.594vw;
        }
        .img3 {
          position: absolute;
          width: 34.333vw;
          height: 29.483vw;
          left: 5.833vw;
          top: 43.036vw;
        }
        .img4 {
          position: absolute;
          width: 45.556vw;
          height: 29.394vw;
          left: 47.889vw;
          top: -7vw;
        }
      }
    }
  }
`;

const Main9 = ({ scrollPosition, isDeviceVer }) => {
  const animation = {
    0: useScrollFadeIn("up", 1, 0),
    1: useScrollFadeIn("up", 1, 0.1),
    2: useScrollFadeIn("up", 1, 0.2),
    3: useScrollFadeIn("up", 1, 0.3),

    4: useScrollFadeIn("up", 1, 0.4),

    5: useScrollFadeIn("right", 1, 0.5),
    6: useScrollFadeIn("right", 1, 0.5),
    7: useScrollFadeIn("right", 1, 0.5),
    8: useScrollFadeIn("right", 1, 0.5),
  };

  return (
    <Container>
      <div className="hide-480">
        <Controller>
          <Scene duration={isDeviceVer === 3 ? 3000 : 2000} triggerHook="onLeave" pin>
            {(progress) => (
              <div className="sticky sticky-one">
                <Timeline totalProgress={progress} paused>
                  <Tween>
                    <div className="animation">
                      <img className="img4" src={icon2}  alt="img80"/>
                    </div>
                  </Tween>
                  <Tween>
                    <div className="animation">
                      <img
                      alt="img81"
                        className={
                          scrollPosition > 40
                            ? "img3 animate__animated animate__fadeInDown"
                            : "img3 animate__animated animate__fadeOutUp"
                        }
                        loading="lazy"
                        src={icon1}
                      />
                    </div>
                  </Tween>
                  <Tween>
                    <div>
                      <img
                      alt="img82"
                        className={
                          scrollPosition > 56
                            ? "img5 animate__animated animate__fadeInDown"
                            : "img5 animate__animated animate__fadeOutUp"
                        }
                        loading="lazy"
                        src={message1}
                      />
                    </div>
                  </Tween>
                  {progress > 0.5 ? (
                    <>
                      <Timeline totalProgress={progress} paused>
                        <Tween from={{ opacity: 1 }} to={{ opacity: 0 }}>
                          <div className="animation">
                            <img  alt="img83"className="img1" loading="lazy" src={outSideKiosk} />
                          </div>
                        </Tween>
                      </Timeline>

                      <Timeline totalProgress={progress} paused>
                        <Tween from={{ opacity: 0 }} to={{ opacity: 1 }}>
                          <div className="animation">
                            <img alt="img84" className="img2" loading="lazy" src={inSideKiosk} />
                          </div>
                        </Tween>
                      </Timeline>
                    </>
                  ) : (
                    <img alt="img85" className="img1" loading="lazy" src={outSideKiosk} />
                  )}
                </Timeline>
                <Timeline
                  target={
                    <div className="main9-left-div">
                      <img alt="img86" className="logo" loading="lazy" src={logo} />

                      <div className="main9-title">
                        <span>양면형 키오스크로</span>
                        <span>완벽하게</span>
                        <span>매장 관리 하세요.</span>
                      </div>
                      <div className="main9-text">
                        <span>
                          지문 바코드 핀 번호로
                          <br />
                          언제 어디서나 매장관리가 가능합니다.
                        </span>
                      </div>
                      <a
                        href={mainNotionUrl.Letmeup}
                        target={'_blank'}
                      >
                        <GhostBtn>
                          <p>자세히 보기</p>
                          <div className="icon-mask" style={{ top: "35%" }}>
                            <object className="icon" data={arrow} />
                            <object className="icon" data={arrowActive} />
                          </div>
                        </GhostBtn>
                      </a>
                    </div>
                  }
                />
              </div>
            )}
          </Scene>
        </Controller>
      </div>
      <div className="show-480">
        <img alt="img87" className="icon1" src={icon1} />
        <img alt="img88" className="icon2" src={icon2} />
        <div className="main9-top-div">
          <img alt="img90" {...animation[0]} className="logo" src={logo} />
          <div className="main9-title">
            <span {...animation[1]}>양면형 키오스크로</span>
            <span {...animation[2]}>완벽하게</span>
            <span {...animation[3]}>매장 관리 하세요.</span>
          </div>
          <div {...animation[4]} className="main9-text">
            지문 바코드 핀 번호로
            <br />
            언제 어디서나 매장관리가 가능합니다.
          </div>
          <a
            href={(mainNotionUrl.Letmeup)}
          >
            <GhostBtn
              style={{
                marginBottom: "0",
                fontSize: "3.889vw",
              }}
            >
              <p>자세히 보기</p>
              <div className="icon-mask" style={{ top: "40%" }}>
                <object className="icon" data={arrow} />
                <object className="icon" data={arrowActive} />
              </div>
            </GhostBtn>
          </a>
        </div>

        <div className="main9-img-div">
          <img alt="img92" {...animation[5]} className="img1" src={m_outSideKiosk} />
          <img alt="img93" {...animation[6]} className="img2" src={m_inSideKiosk} />
          <img alt="img94" {...animation[7]} className="img4" src={m_message1} />
        </div>
      </div>
    </Container>
  );
};

export default Main9;
