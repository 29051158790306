import * as React from "react";
import useScrollFadeIn from "../useScrollFadein";
import backgroundMedia from "../../images/main1.mp4";
import srcollMedia from "../../images/scroll.svg";
import styled from "styled-components";

const Containar = styled.div` 
position:relative;
width:100vw;
height:100vh;
.video-filter{
    width: 100vw;
    height:100vh;
    position: relative;
    top:0;
    left:0;
    bottom:0;
    right:0;
    z-index: -1;
    filter: brightness(0.7);
   video {
    width: 100vw;
    height:100vh;
    object-fit:cover;
    position: inherit;
  }
}
.main1-text{
  width: 62%;
  margin: 0 19%;
  position: absolute;
  height: 100vh;
  display: flex;
  align-items: center;
  .main1-title {
    font-family: 'Pretendard-ExtraBold';
    color:#ffffff;
    font-size: 4vw;
    line-height: 1.2;
    display: flex;
    flex-direction: column;
  }
}
object{
  width: 2vw;
  position:absolute;
  bottom: 3vw;
  right: 47%;
} 
@media screen and (max-width: 1020px){
  .main1-text{
    margin: 0 7%;
    .main1-title {
      font-size: 7vw;
    }
  }
}
@media screen and (max-width: 460px){
  .video-filter{
    max-width: 460px;
    max-height: 100vh;
    height:100vh;
    overflow: hidden;
    video {
      height: 100%;
      width: auto;
      margin-left: -75vh;
    }
  }

  .main1-text {
    width: 90%;
    margin: 0 5%;
    .main1-title {
      font-family: 'Pretendard-Bold';
      font-size: 10.5vw;
    
    }
  }

  object{
    width: 6vw;
    bottom: 10vw;
  }
}
`;

// markup
const Main1 = () => {
  const animation = {
    0: useScrollFadeIn("up", 1, 0),
    1: useScrollFadeIn("up", 1, 0.1),
    2: useScrollFadeIn("up", 1, 0.2),
  };

  return (
    <Containar>
      <object data={srcollMedia}></object>
      <div className="main1-text">
        <p className="main1-title">
          <span {...animation[0]}> 모두가</span>
          <span {...animation[1]}>함께 성장하며</span>
          <span {...animation[2]}> 시간을 보내도록</span>
        </p>
      </div>
      <div className="video-filter">
        <video
          src="https://samstoragekr.s3.ap-northeast-2.amazonaws.com/main1.mp4"
          type="video/mp4"
          autoPlay
          muted
          playsinline="true"
          loop
        ></video>
      </div>
    </Containar>
  );
};

export default Main1;
