import * as React from "react";
import useScrollFadeIn from "../useScrollFadein";
import backgroundMedia from "../../images/main3.mp4";
import styled from "styled-components";
const Containar = styled.div`
  .video-filter {
    width: 100%;
    height: 35vw;
    overflow: hidden;
    position: relative;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: -1;
    video {
      width: 100%;
      height: 100%;
      position: inherit;
      object-fit: cover;
    }
  }
  .color-filter {
    background: rgba(1, 195, 197, 0.6);
    position: absolute;
    z-index: 5;
    width: 100%;
    height: 35vw;
  }

  .main3-text {
    width: 62%;
    height: 35vw;
    margin: 0 19%;
    position: absolute;
    .main3-title {
      color: #ffffff;
      font-size: 3.1vw;
      margin: 8vw 0 0;
      line-height: 1.2;
      display: flex;
      flex-direction: column;
      span:not(:last-child) {
        color: rgba(255, 255, 255, 0.6);
      }
    }
  }
  object {
    width: 2vw;
    display: block;
    margin: 11vw auto 0;
  }

  .big-font {
    position: absolute;
    color: rgba(255, 255, 255, 0.1);
    font-size: 9vw;
    white-space: nowrap;
    bottom: -1vw;
  }

  .show-480 {
    display: none;
  }
  @media screen and (max-width: 1020px) {
    .video-filter {
      height: 50vw;
    }
    .color-filter {
      height: 50vw;
    }
    .main3-text {
      width: fit-content;
      margin: 0 7%;
      height: 50vw;
      .main3-title {
        margin: 15vw 0;
        font-size: 4.6vw;
      }
    }
    .big-font {
      text-align: right;
      width: 90vw;
    }
  }
  @media screen and (max-width: 460px) {
    .main3-text {
      margin: 38vw 5% 0;
      .main3-title {
        font-size: 7.5vw;
      }
      .big-font {
        font-size: 16vw;
        bottom: -44vw;
      }
    }

    .video-filter {
      height: 120vw;
      .color-filter {
        height: 120vw;
      }
    }

    .show-480 {
      display: block;
    }
  }
`;

// markup
const Main3 = () => {
  const animation = {
    0: useScrollFadeIn("up", 1, 0),
    1: useScrollFadeIn("up", 1, 0.1),
    2: useScrollFadeIn("up", 1, 0.2),
  };
  return (
    <Containar>
      <div>
        <div className="main3-text">
          <div className="main3-title">
            <span {...animation[0]}>가치있는 일</span>
            <span {...animation[1]}>즐거운 일로</span>
            <span {...animation[2]}>일상을 채우도록</span>
          </div>

          <p className="big-font">
            Unmanned <br className="show-480" />
            System
          </p>
        </div>
        <div className="video-filter">
          <div className="color-filter" />
          <video
            src="https://samstoragekr.s3.ap-northeast-2.amazonaws.com/main3.mp4"
            type="video/mp4"
            autoPlay
            muted
            playsinline="true"
            loop
          ></video>
        </div>
      </div>
    </Containar>
  );
};

export default Main3;
