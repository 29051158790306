import * as React from "react";
import arrow from "../../images/arrow.svg";
import arrowActive from "../../images/arrow-active.svg";
import outSideKiosk from "../../images/main7/외부_스쿨업.svg";
import m_outSideKiosk from "../../images/main7/m_외부_스쿨업_x3.png";
import inSidekiosk from "../../images/main7/내부_스쿨업.svg";
import m_inSidekiosk from "../../images/main7/m_내부_스쿨업_x3.png";

import character from "../../images/main7/캐릭터.png";
import logo from "../../images/main7/스쿨업로고.png";
import message1 from "../../images/main7/message1.png";
import message2 from "../../images/main7/message2.png";
import m_message2 from "../../images/main7/e_숙제도움받아볼까 e.svg";

import m_message1 from "../../images/main7/e_식단도움받아볼까.svg";
import { GhostBtn } from "./main5";
import { Link } from "gatsby";
import "./animate.css";
import useScrollFadeIn from "../useScrollFadein";
import styled from "styled-components";

import { mainNotionUrl } from "../../data/url";

const Containar = styled.div`
  display: flex;
  position: relative;
  height: 75vw;
  margin-top: 5vw;
  img {
    object-fit: contain;
  }
  .main7-left-div {
    position: absolute;
    width: 23.073vw;
    height: 24.688vw;
    left: 66.406vw;
    top: 16.615vw;
    .logo {
      width: 9.583vw;
      height: 1.667vw;
      margin-bottom: 1.094vw;
    }
    .main7-title {
      font-size: 3.1vw;
      font-family: "Pretendard-ExtraBold";
      line-height: 1.2;
      margin-bottom: 3vw;
      display: flex;
      flex-direction: column;
      
    }

    .main7-text {
      font-family: "Pretendard-Medium";
      font-style: normal;
      font-weight: 500;
      font-size: 1.25vw;
      line-height: 1.2;
      color: #555555;
      margin-top: 1.25vw;
      margin-bottom: 2.448vw;
    }
    .ghostButton {
      padding: 1vw;
      font-size: 1.5vw;
      background-color: transparent;
      border-radius: 0.5vw;
      border: 1.5px solid #000000;
      margin-top: 3vw;
    }
  }
  .main7-right-div {
    width: 50%;
    height: 100%;
    position: relative;
    .nopacity {
      opacity: 0;
    }
    .main7-img-group {
      position: absolute;
      top: 0;
      left: 0;
    }

    .main7-image1 {
      width: 31.848vw;
      height: 52.76vw;
      left: 8.333vw;
      top: 1.563vw;
    }

    .main7-image2 {
      width: 22.584vw;
      height: 33.125vw;
      left: 29.427vw;
      top: 24.375vw;
    }

    .main7-image3 {
      width: 15.99vw;
      height: 5.688vw;
      left: 35.648vw;
      top: 22.406vw;
    }
    .main7-image4 {
      width: 13.203vw;
      height: 5.844vw;
      left: 40.917vw;
      top: 18.354vw;
    }
  }

  .show-480 {
    display: none;
  }
 //a
  .fade-in-left {
    transition-property: transform, opacity;
    transition-duration: 1s;
    transition-timing-function: cubic-bezier(0, 0, 0.2, 1);
    opacity: 1;
    transform: translate3d(50%, 0, 0);
  }
  @media screen and (max-width: 1020px) and (orientation: portrait) {
    width: 100%;
    height: 100vh;
    margin-top: 0;
    padding: 5vw 10%;
    box-sizing: border-box;

    .hide-480 {
      width: 100%;

      .main7-left-div {
        position: static;
        width: 80%;
        margin-top: 5vw;
        .logo {
          width: 17vw;
          height: fit-content;
        }
        .main7-title {
          font-size: 4.6vw;
          display: flex;
          flex-direction: column;
        }
        .main7-text {
          font-size: 2vw;
        }
      }
      .main7-right-div {
        height: fit-content;
        .main7-image1 {
          width: 44.848vw;
          height: fit-content;
          left: 17.333vw;
          top: 44.563vw;
        }

        .main7-image2 {
          width: 34.584vw;
          height: fit-content;
          left: 44.427vw;
          top: 72.375vw;
        }

        .main7-image3 {
          width: 28.99vw;
          height: fit-content;
          left: 57.648vw;
          top: 30.406vw;
        }
        .main7-image4 {
          width: 34.203vw;
          height: fit-content;
          left: -4.083vw;
          top: 81.354vw;
        }
      }
    }
  }
  @media screen and (max-width: 690px) and (orientation: portrait) {
    .hide-480 {
      .main7-right-div {
        .main7-image1 {
          width: 44vw;
          height: fit-content;
          left: 17.333vw;
          top: 42vw;
        }
        .main7-image2 {
          width: 33vw;
          height: fit-content;
          left: 44vw;
          top: 71vw;
        }

        .main7-image3 {
          width: 28.99vw;
          height: fit-content;
          left: 57.648vw;
          top: 30.406vw;
        }
        .main7-image4 {
          width: 33vw;
          height: fit-content;
          left: -4.083vw;
          top: 80vw;
        }
      }
    }
  }
  @media screen and (max-width: 460px) {
    padding: 0;

    height: 202.222vw;
    .main7-right-div {
      margin-top: 0;
      width: 100%;
    }

    .hide-480 {
      display: none;
    }

    .show-480 {
      display: flex;
      flex-direction: column;
      position: relative;
      width: 100%;
      height: 100%;

      .main7-top-div {
        display: flex;
        flex-direction: column;
        justify-content: left;
        margin-top: 124px;
        margin-left: 24px;
        .logo {
          width: 106px;
          height: 18px;
          left: 24px;
          top: 124px;
          margin-bottom: 2.778vw;
        }

        .main7-title {
          font-family: "Pretendard-ExtraBold";
          font-size: 8.333vw;
          line-height: 10vw;
          display: flex;
          flex-direction: column;
        }

        .main7-text {
          font-family: "Pretendard-Medium";
          font-style: normal;
          font-weight: 500;
          font-size: 4.444vw;
          line-height: 5.278vw;
          margin-top: 4.444vw;
          margin-bottom: 12.778vw;
          color: #555555;
        }
        a {
          width: 40.556vw;
          height: 13.333vw;
        }
      }

      .main7-img-div {
        position: relative;
        height: 100%;
        .img1 {
          position: absolute;
          width: 45.275vw;
          height: 75.003vw;
          left: 22.222vw;
          top: 8.056vw;
        }
        .img2 {
          position: absolute;
          width: 31.539vw;
          height: 54.258vw;
          left: 50.961vw;
          top: 40.594vw;
        }
        .img3 {
          position: absolute;
          width: 40.333vw;
          height: 29.483vw;
          left: 2.833vw;
          top: 41.036vw;
        }
        .img4 {
          position: absolute;
          width: 40.333vw;
          height: 29.483vw;
          left: 51.889vw;
          top: -7vw;
        }
      }
    }
  }
`;

// markup
//
const Main7 = ({ scrollPosition }) => {
  const animation = {
    0: useScrollFadeIn("up", 1, 0),
    1: useScrollFadeIn("up", 1, 0.1),
    2: useScrollFadeIn("up", 1, 0.2),
    3: useScrollFadeIn("up", 1, 0.3),

    4: useScrollFadeIn("up", 1, 0.4),

    5: useScrollFadeIn("right", 1, 0.5),
    6: useScrollFadeIn("right", 1, 0.5),
    7: useScrollFadeIn("right", 1, 0.5),
    8: useScrollFadeIn("right", 1, 0.5),
  };

  return (
    <Containar>
      <div className="hide-480">
        <div className="main7-right-div">
          <img
          alt='img58'
            className="main7-img-group main7-image1"
            loading="lazy"
            src={outSideKiosk}
          />
          <img
          alt='img59'
            className="main7-img-group main7-image2"
            loading="lazy"
            src={inSidekiosk}
          />
          <img
          alt='img60'
            className={
              scrollPosition > 5
                ? "main7-img-group main7-image3 animate__animated animate__fadeInDown"
                : "main7-img-group main7-image3"
            }
            loading="lazy"
            src={message1}
          />
          <img
          alt='img61'
            className={
              scrollPosition > 5
                ? "main7-img-group main7-image4 animate__animated animate__fadeInDown animate__delay-1s"
                : "main7-img-group main7-image4"
            }
            loading="lazy"
            src={message2}
          />
        </div>
        <div className="main7-left-div">
          <img alt='img62' className="logo" loading="lazy" src={logo} />

          <div className="main7-title">
            <span>진로 진학 컨설팅</span>
            <span>상담 신청</span>
            <span>학교 플랫폼</span>
          </div>

          <div className="main7-text">
            <span>
              우리 학교 진로 도우미,
              <br />
              학생과 선생님의 상담도 간편하게
            </span>
          </div>
          <a
            href={mainNotionUrl.Schoolup}
            target={'_blank'}
          >
            <GhostBtn>
              <p>자세히 보기</p>
              <div className="icon-mask" style={{ top: "35%" }}>
                <object className="icon" data={arrow} />
                <object className="icon" data={arrowActive} />
              </div>
            </GhostBtn>
          </a>
        </div>
      </div>
      <div className="show-480">
        <div className="main7-top-div">
          <img alt='img63' {...animation[0]} className="logo" src={logo} />
          <div className="main7-title">
            <span {...animation[1]}>진로 진학 컨설팅</span>
            <span {...animation[2]}>상담 신청</span>
            <span {...animation[3]}>학교 플랫폼</span>
          </div>
          <div {...animation[4]} className="main7-text">
            우리 학교 진로 도우미,
            <br />
            학생과 선생님의 상담도 간편하게
          </div>
          <a
            href={mainNotionUrl.Schoolup}
            target={'_blank'}
          >
            <GhostBtn
              style={{
                marginBottom: "0",
                fontSize: "3.889vw",
              }}
            >
              <p>자세히 보기</p>
              <div className="icon-mask" style={{ top: "40%" }}>
                <object className="icon" data={arrow} />
                <object className="icon" data={arrowActive} />
              </div>
            </GhostBtn>
          </a>
        </div>

        <div className="main7-img-div">
          <img alt='img64' {...animation[5]} className="img1" src={m_outSideKiosk} />
          <img alt='img65' {...animation[6]} className="img2" src={m_inSidekiosk} />
          <img alt='img66' {...animation[7]} className="img3" src={m_message1} />
          <img alt='img67' {...animation[8]} className="img4" src={m_message2} />
        </div>
      </div>
    </Containar>
  );
};

export default Main7;
