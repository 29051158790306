import * as React from "react";
import arrow from "../../images/arrow.svg";
import arrowActive from "../../images/arrow-active.svg";
import outSideKiosk from "../../images/main8/외부_클래스업.svg";
import m_outSideKiosk from "../../images/main8/m_외부_클래스업_x3.png";

import inSideKiosk from "../../images/main8/내부_클래스업.svg";
import m_inSideKiosk from "../../images/main8/m_내부_클래스업_x3.png";

import logo from "../../images/main8/클래스업로고.png";
import icon1 from "../../images/main8/s_아이콘 1.png";
import m_icon1 from "../../images/main8/m_아이콘 1.png";

import icon2 from "../../images/main8/e_아이콘 2.png";
import m_icon2 from "../../images/main8/m_아이콘 2.png";


import message1 from "../../images/main8/e_입실준비하자.png";
import m_message1 from "../../images/main8/e_입실준비.svg";

import { GhostBtn } from "./main5";
import { Link } from "gatsby";
import "./animate.css";
import useScrollFadeIn from "../useScrollFadein";
import styled from "styled-components";
import { mainNotionUrl } from "../../data/url";
import { Controller, Scene } from "react-scrollmagic";
import { Timeline, Tween } from "react-gsap";

const Container = styled.div`
  img {
    object-fit: contain;
  }
  .hide-480 {
    display: flex;
    overflow: hidden;
    position: relative;
    top: 0;
    left: 0;
    margin-bottom: 5vw;
    .main8-left-div {
      position: absolute;
      width: 23.073vw;
      height: 24.688vw;
      left: 13.542vw;
      top: 10.677vw;
      .logo {
        width: 9.583vw;
        /* height: 2.667vw; */
        margin-bottom: 1.094vw;
      }
      .main8-title {
        font-size: 3.1vw;
        font-family: "Pretendard-ExtraBold";
        line-height: 1.2;
        margin-bottom: 3vw;
        display: flex;
        flex-direction: column;
      }
      .main8-text {
        font-family: "Pretendard-Medium";
        font-style: normal;
        font-weight: 500;
        font-size: 1.25vw;
        line-height: 1.2;
        color: #555555;
        margin-top: 1.25vw;
        margin-bottom: 2.448vw;
      }
      .ghostButton {
        padding: 1vw;
        font-size: 1.5vw;
        background-color: transparent;
        border-radius: 0.5vw;
        border: 1.5px solid #000000;
        margin-top: 3vw;
      }
    }
    //외부 키오스크
    .img1 {
      position: absolute;
      width: 36.927vw;
      height: 52.24vw;
      left: 57.344vw;
      top: 2.813vw;
      opacity: 1;
    }
    //내부 키오스크
    .img2 {
      position: absolute;
      width: 32.552vw;
      height: 39.531vw;
      left: 59.427vw;
      top: 9.844vw;
      opacity: 1;
    }
    //아이콘 2
    .img3 {
      position: absolute;
      width: 9.545vw;
      height: 9.545vw;
      left: 46.137vw;
      top: 17.017vw;
    }
    // 아이콘2
    .img4 {
      position: absolute;
      width: 7.788vw;
      height: 7.788vw;
      left: 37.135vw;
      top: 11.51vw;
    }
    // 메세지1
    .img5 {
      position: absolute;
      width: 13.438vw;
      height: 6.838vw;
      left: 50vw;
      top: 5.521vw;
    }
    .sticky,
    .sticky2 {
      height: 60vw;

      & .animation,
      .animation2 {
      }
    }
  }
  .show-480 {
    display: none;
  }
  @media screen and (max-width: 1020px) and (orientation: portrait) {
    .hide-480 {
      margin: 5vw 0;
      padding-bottom: 30vw;
      .main8-left-div {
        width: 80vw;
        left: 10vw;
        top: 6vw;
        .logo {
          width: 17vw;
        }
        .main8-title {
          font-size: 4.6vw;
          display: flex;
        flex-direction: column;
        }
        .main8-text {
          font-size: 2vw;
        }
      }
      //외부 키오스크
      .img1 {
        width: 33rem;
        height: fit-content;
        left: 15rem;
        top: 26.5rem;
      }
      //내부 키오스크
      .img2 {
        width: 35rem;
        height: fit-content;
        left: 12rem;
        top: 35rem;
      }
      //아이콘 2
      .img3 {
        width: 9.545vw;
        height: fit-content;
        left: 50vw;
        top: 17.017vw;
      }
      // 아이콘2
      .img4 {
        width: 7.788vw;
        height: fit-content;
        left: 37.135vw;
        top: 8.51vw;
      }
      // 메세지1
      .img5 {
        width: 23.438vw;
        height: fit-content;
        left: 64vw;
        top: 32.521vw;
      }
      .sticky,
      .sticky2 {
        height: 120vw;
      }
    }
  }

  @media screen and (max-width: 690px) and (orientation: portrait) {
    .hide-480 {
      //외부 키오스크
      .img1 {
        width: 22rem;
        height: fit-content;
        left: 16rem;
        top: 17.5rem;
      }
      //내부 키오스크
      .img2 {
        width: 23rem;
        height: fit-content;
        left: 14rem;
        top: 23.5rem;
      }
      //아이콘 2
      .img3 {
        width: 9.545vw;
        height: fit-content;
        left: 50vw;
        top: 17.017vw;
      }
      // 아이콘2
      .img4 {
        width: 7.788vw;
        height: fit-content;
        left: 37.135vw;
        top: 8.51vw;
      }
      // 메세지1
      .img5 {
        width: 23.438vw;
        height: fit-content;
        left: 64vw;
        top: 32.521vw;
      }
      .sticky,
      .sticky2 {
        height: 90vw;
      }
    }
  }
  @media screen and (max-width: 460px) {
    height: 220.222vw;
    .main8-right-div {
      margin-top: 0;
      width: 100%;
    }

    .hide-480 {
      display: none;
    }

    .show-480 {
      display: flex;
      flex-direction: column;
      position: relative;
      width: 100%;
      height: 100%;
      .icon1 {
        position: absolute;
        width: 23.969vw;
        height: 23.969vw;
        left: 67.5vw;
        top: 18.808vw;
      }
      .icon2 {
        position: absolute;
        width: 16.769vw;
        height: 16.769vw;
        left: 45.556vw;
        top: 10.278vw;
      }
      .main8-top-div {
        display: flex;
        flex-direction: column;
        justify-content: left;
        margin-top: 124px;
        margin-left: 24px;
        .logo {
          width: 28.056vw;
          height: 8.611vw;
          left: 5vw;
          top: 32.778vw;
          margin-bottom: 2.778vw;
        }

        .main8-title {
          font-family: "Pretendard-ExtraBold";
          font-size: 8.333vw;
          line-height: 10vw;
          display: flex;
        flex-direction: column;
        }

        .main8-text {
          font-family: "Pretendard-Medium";
          font-style: normal;
          font-weight: 500;
          font-size: 4.444vw;
          line-height: 5.278vw;
          margin-top: 4.444vw;
          margin-bottom: 12.778vw;
          color: #555555;
        }
        a {
          width: 40.556vw;
          height: 13.333vw;
        }
      }

      .main8-img-div {
        position: relative;
        height: 100%;
        .img1 {
          position: absolute;
          width: 53.275vw;
          height: 75.003vw;
          left: 18.222vw;
          top: 8.056vw;
        }
        .img2 {
          position: absolute;
          width: 36.539vw;
          height: 46.258vw;
          left: 46.961vw;
          top: 47.594vw;
        }
        .img3 {
          position: absolute;
          width: 34.333vw;
          height: 29.483vw;
          left: 5.833vw;
          top: 43.036vw;
        }
        .img4 {
          position: absolute;
          width: 40.556vw;
          height: 27.394vw;
          left: 50.889vw;
          top: -6vw;
        }
      }
    }
  }
`;

const Main8 = ({ scrollPosition, isDeviceVer }) => {
  const animation = {
    0: useScrollFadeIn("up", 1, 0),
    1: useScrollFadeIn("up", 1, 0.1),
    2: useScrollFadeIn("up", 1, 0.2),
    3: useScrollFadeIn("up", 1, 0.3),

    4: useScrollFadeIn("up", 1, 0.4),

    5: useScrollFadeIn("right", 1, 0.5),
    6: useScrollFadeIn("right", 1, 0.5),
    7: useScrollFadeIn("right", 1, 0.5),
  };
  return (
    <Container>
      <div className="hide-480">
        <Controller>
          <Scene duration={isDeviceVer === 3 ? 3000 : 2000} triggerHook="onLeave" pin>
            {(progress) => (
              <div className="sticky sticky-one">
                <Timeline
                  target={
                    <div className="main8-left-div">
                      <img alt="img68"  className="logo" loading="lazy" src={logo} />

                      <div className="main8-title">
                        <span>AI 출석부 관리</span>
                        <span>수납 자동화</span>
                        <span>관리형 학원 플랫폼</span>
                      </div>

                      <div className="main8-text">
                        <span>
                          AI 관리형 학원 키오스크로
                          <br />
                          월600만원을 아끼세요!
                        </span>
                      </div>
                      <a
                        href={mainNotionUrl.Classup}
                        target={'_blank'}
                        >
                        <GhostBtn>
                          <p>자세히 보기</p>
                          <div className="icon-mask" style={{ top: "35%" }}>
                            <object className="icon" data={arrow} />
                            <object className="icon" data={arrowActive} />
                          </div>
                        </GhostBtn>
                      </a>
                    </div>
                  }
                ></Timeline>
                <Tween>
                  <div className="animation">
                    <img alt="img69" className="img4" loading="lazy" src={icon2} />
                  </div>
                </Tween>
                <Tween>
                  <div className="animation">
                    <img
                    alt="img70"
                      className={
                        scrollPosition > 18
                          ? "img3 animate__animated animate__fadeInDown"
                          : "img3 animate__animated animate__fadeOutUp"
                      }
                      loading="lazy"
                      src={icon1}
                    />
                  </div>
                </Tween>
                <Tween>
                  <div>
                    <img
                    alt="img71"
                      className={
                        scrollPosition > 23
                          ? "img5 animate__animated animate__fadeInDown"
                          : "img5 animate__animated animate__fadeOutUp"
                      }
                      loading="lazy"
                      src={message1}
                    />
                  </div>
                </Tween>
                {progress > 0.5 ? (
                  <>
                    <Timeline totalProgress={progress} paused>
                      <Tween from={{ opacity: 1 }} to={{ opacity: 0 }}>
                        <div className="animation">
                          <img alt="img72" className="img1" loading="lazy" src={outSideKiosk} />
                        </div>
                      </Tween>
                    </Timeline>
                    <Timeline totalProgress={progress} paused>
                      <Tween from={{ opacity: 0 }} to={{ opacity: 1 }}>
                        <div className="animation">
                          <img alt="img73" className="img2" loading="lazy" src={inSideKiosk} />
                        </div>
                      </Tween>
                    </Timeline>
                  </>
                ) : (
                  <img alt="img74" className="img1" loading="lazy" src={outSideKiosk} />
                )}
              </div>
            )}
          </Scene>
        </Controller>
      </div>

      <div className="show-480">
        <img alt="img75"className="icon1" src={m_icon1} />
        <img alt="img76"className="icon2" src={m_icon2} />
        <div className="main8-top-div">
          <img alt="img77" {...animation[0]} className="logo" src={logo} />
          <div className="main8-title">
            <span {...animation[1]}>AI 출석부 관리</span>
            <span {...animation[2]}>수납 자동화</span>
            <span {...animation[3]}>관리형 학원 플랫폼</span>
          </div>
          <div {...animation[4]} className="main8-text">
            AI 관리형 학원 키오스크로
            <br />월 600만원을 아끼세요!
          </div>
          <a
            href={(mainNotionUrl.Classup)}
            target={'_blank'}
          >
            <GhostBtn
              style={{
                marginBottom: "0",
                fontSize: "3.889vw",
              }}
            >
              <p>자세히 보기</p>
              <div className="icon-mask" style={{ top: "40%" }}>
                <object className="icon" data={arrow} />
                <object className="icon" data={arrowActive} />
              </div>
            </GhostBtn>
          </a>
        </div>

        <div className="main8-img-div">
          <img alt="img77" {...animation[5]} className="img1" src={m_outSideKiosk} />
          <img alt="img78" {...animation[6]} className="img2" src={m_inSideKiosk} />
          <img alt="img79" {...animation[7]} className="img4" src={m_message1} />
        </div>
      </div>
    </Container>
  );
};

export default Main8;
