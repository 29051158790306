import * as React from "react";
import styled from "styled-components";
import arrow from "../../images/arrow.svg";
import arrowActive from "../../images/arrow-active.svg";
import img from "../../images/main6-img.png";
import { GhostBtn } from "./main5";
import useScrollFadeIn from "../useScrollFadein";
import useScrollMove from "../useScrollMove";

const Container = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  .main {
    margin: 10vw 15%;
    display: flex;
    justify-content: space-between;
    .main-title {
      font-family: "Pretendard-ExtraBold";
      font-size: 3.1vw; //60px
      white-space: pre-wrap;
      line-height: 1.2;
    }
    .main-sub {
      font-family: "Pretendard-Medium";

      color: #555555;
      font-size: 1.25vw; //24px
      margin: 1.8vw 0 3.6vw;
    }
  }
  .ghostButton {
    font-family: "Pretendard-Bold";
    padding: 1vw;
    font-size: 0.8vw; //16px
    background-color: transparent;
    border-radius: 0.5vw;
    border: 1.5px solid #000000;
    display: flex;
    object {
      width: 2.4vw;
    }

    p {
      margin-right: 0.5vw;
    }
  }
  .main__column:last-child {
    img {
      width: 30vw;
    }
    margin-left: 10vw;
  }
  @media screen and (max-width: 1020px) {
    .main {
      margin: 15% 10%;
      .main-title {
        font-size: 4.6vw;
      }
      .main-sub {
        font-size: 2vw;
      }
    }
    .main__column:last-child {
      img {
        width: 36vw;
      }
      margin-left: 8vw;
    }
  }
  @media screen and (max-width: 460px) {
    justify-content: flex-start;
    .main {
      flex-direction: column;
      margin: 30vw 5% 0vw;
      .main-title {
        font-size: 7.5vw;
      }
      .main-sub {
        font-size: 3.5vw;
        margin: 4vw 0 6vw;
      }
    }

    .main__column:last-child {
      margin-left: -5vw;
      img {
        width: 87vw;
        margin-top: 5vw;
        margin-bottom: 20vw;
      }
    }
    .m-width {
      width: 50.278vw;
      height: 13.333vw;
      margin-bottom: 15vw;
    }
  }
`;
const Box = styled.div`
  position: absolute;
  top: 10%;
  right: 0;
  background-color: #01a6b8;
  width: 40vw;
  height: 20vw;
  z-index: -99;
  @media screen and (max-width: 460px) {
    top: 46%;
    width: 68vw;
    height: 45vw;
  }
`;
function Main6() {
  const animation = {
    0: useScrollFadeIn("up", 1, 0),
    1: useScrollFadeIn("up", 1, 0.2),
    2: useScrollFadeIn("up", 1, 0.4),
  };
  const moveAnimate = {
    0: useScrollMove("left", 1, 0.4),
  };
  return (
    <Container>
      <div className="main">
        <div className="main__column">
          <div className="main-title">
            <p {...animation[0]}>함께 성장하실</p>
            <p {...animation[1]}>파트너를 모십니다.</p>
          </div>
          <p {...animation[2]} className="main-sub">
            미래를 준비하는 공간, 함께 만들어 가세요
          </p>
          <div className="m-width">
            <GhostBtn>
              <p>Join partner</p>
              <div className="icon-mask" style={{ top: "39%" }}>
                <object className="icon" data={arrow} />
                <object className="icon" data={arrowActive} />
              </div>
            </GhostBtn>
          </div>
        </div>
        <div className="main__column">
          <Box></Box>
          <img {...moveAnimate[0]} src={img} alt='img56'/>
        </div>
      </div>
    </Container>
  );
}

export default Main6;
